import { createSlice } from "@reduxjs/toolkit";
import * as actions from "./bankAction";

export const bankSlice = createSlice({
  name: "bank",
  initialState: {
    getBankDetails: [],
    setBankDetailsForm :'account',
    getBankDropDownDetails: [],
    getBankBranchDetails:[],
    addBank:[],
  },
  reducers: {
    setBankDetailsForm: (state, action) => {
      state.setBankDetailsForm = action.payload
    },
    reset(state) {
      for (const key in state) {
        state[key] = [];
      }
    },
    
  },
  extraReducers: (builder) => {
    builder
    .addCase(actions.GetBankDetails.fulfilled, (state, action) => {
      state.getBankDetails = action.payload.getBankDetails
      })
    .addCase(actions.GetBankDropDownDetails.fulfilled, (state, action) => {
      state.getBankDropDownDetails = action.payload.getBankDropDownDetails
      })
    .addCase(actions.GetBankBranchDetails.fulfilled, (state, action) => {
      state.getBankBranchDetails = action.payload.getBankBranchDetails
      })
    .addCase(actions.AddBank.fulfilled, (state, action) => {
      state.addBank = action.payload.addBank
      })
  },
});

export const { setBankDetailsForm, reset } = bankSlice.actions

export default bankSlice.reducer;
