import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { bankApiEndPoint } from "../../configs/serverApiConfig";

const apiUrl = process.env.REACT_APP_API_BASEURL;
const accessToken = localStorage.getItem("accessToken");

export const GetBankDetails = createAsyncThunk(
  "User/getBankDetails",
  async (thunkAPI) => {
    try {
      const response = await axios.get(`${apiUrl}${bankApiEndPoint.GetBank}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return { data: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const GetBankDropDownDetails = createAsyncThunk(
  "User/getBankDropDownDetails",
  async (thunkAPI) => {
    try {
      const response = await axios.get(
        `${apiUrl}${bankApiEndPoint.GetAllBank}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { data: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const GetBankBranchDetails = createAsyncThunk(
  "User/getBankBranchDetails",
  async (thunkAPI) => {
    try {
      const response = await axios.get(
        `${apiUrl}${bankApiEndPoint.getAllBranch}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { data: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const AddBank = createAsyncThunk(
  "/Bank/AddBank",
  async (BankData, thunkAPI) => {
    try {
      const response = await axios.post(
        `${apiUrl}${bankApiEndPoint.AddBank}`,
        BankData
      );
      return { data: response };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
