import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import axios from "axios";
import { authApiEndPoint, baseurl } from "../../configs/serverApiConfig.js";

const accessToken = localStorage.getItem("accessToken");

export const VerifyMobileNumber = createAsyncThunk(
  "/User/VerifyMobile",
  async (mobileNumber, thunkAPI) => {
    try {
      const response = await axios.post(
        `${baseurl.authBaseURL}${authApiEndPoint.VerifyMobile}`,
        {
          mobile: mobileNumber, // Pass the row.id as a parameter
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      // console.log(response, 'Calenderdata')
      console.log(response?.data, "MobileData");
      return { data: response?.data };
    } catch (error) {
      // console.log(error, "eroor");
      // console.log(error.response.data, "eroor in mobile data");
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const VerifyOTPEmail = createAsyncThunk(
  "/User/VerifyOTP",
  async (OTPDATA, thunkAPI) => {
    try {
      const response = await axios.post(
        `${baseurl.authBaseURL}${authApiEndPoint.VerifyOTP}`,
        OTPDATA
      );

      return { data: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const VerifyEmail = createAsyncThunk(
  "/User/VerifyEmail",
  async (emailId, thunkAPI) => {
    try {
      const response = await axios.post(
        `${baseurl.authBaseURL}${authApiEndPoint.VerifyEmail}`,
        {
          email: emailId, // Pass the row.id as a parameter
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { data: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const VerifyOTPMobile = createAsyncThunk(
  "/User/VerifyMobileOTP",
  async (OTPDATA, thunkAPI) => {
    try {
      const response = await axios.post(
        `${baseurl.authBaseURL}${authApiEndPoint.VerifyOTP}`,
        OTPDATA
      );
      // console.log(response, 'Calenderdata')
      return { data: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const ChangePassword = createAsyncThunk(
  "/User/changePassword",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(
        `${baseurl.authBaseURL}${authApiEndPoint.changePassword}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { data: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const ForgotPassword = createAsyncThunk(
  "/user/ForgotPassword",
  async (data, thunkAPI) => {
    console.log(data, "dataOfPayl");
    try {
      const response = await axios.put(
        `${baseurl.authBaseURL}${authApiEndPoint?.ForgotPassword}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { data: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const ForgotPasswordWithToken = createAsyncThunk(
  "/user/ForgotPasswordWithToken",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(
        `${baseurl.authBaseURL}${authApiEndPoint?.ForgotPasswordWithTOken}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { data: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const resetAsyncThunksSignUpPage = createAction(
  "employMangeTableData/resetAsyncThunks"
);
