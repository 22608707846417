import { createSlice } from "@reduxjs/toolkit";
import * as actions from "./dashboardAction";

export const dashBoardSlice = createSlice({
  name: "dashBoard",
  initialState: {
    dashBoardData: [],
    loading: false,
    error: null,
  },
  reducers: {
    reset(state) {
      for (const key in state) {
        state[key] = [];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actions.getDashboardData.pending, (state) => {
        state.loading = true;
      })
      .addCase(actions.getDashboardData.fulfilled, (state, action) => {
        state.dashBoardData = action.payload.data;
        state.loading = false;
      })
      .addCase(actions.getDashboardData.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      });
  },
});
export const { reset } = dashBoardSlice.actions;

export default dashBoardSlice.reducer;
