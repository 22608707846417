import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  baseurl,
  companyApiEndPoint,
  companyProfileApiEndPoint,
} from "../../configs/serverApiConfig";

const apiUrl = process.env.REACT_APP_API_BASEURL;
const accessToken = localStorage.getItem("accessToken");

export const UpdateCompanyProfile = createAsyncThunk(
  "/Admin/UpdateCompanyProfile",
  async (UpdateCompanyData, thunkAPI) => {
    try {
      const response = await axios.put(
        `${baseurl.authBaseURL}${companyProfileApiEndPoint.UpdateProfile}/${UpdateCompanyData?.virtualId}`,
        UpdateCompanyData
      );

      return { updateCompanyProfile: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const UpdateCompanyProfileByUser = createAsyncThunk(
  "/Admin/UpdateCompanyProfileByUser",
  async (UpdateCompanyProfileUserData, thunkAPI) => {
    try {
      const response = await axios.put(
        `${apiUrl}${companyApiEndPoint.UpdateCompanyProfileByUser}`,
        UpdateCompanyProfileUserData
      );
      return { updateCompanyProfileUser: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const UpdateCompany = createAsyncThunk(
  "/User/UpdateCompany",
  async (formdata, thunkAPI) => {
    try {
      const response = await axios.put(
        `${apiUrl}${companyApiEndPoint.UpdateCompany}`,
        formdata,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { updateCompany: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const GetProfile = createAsyncThunk(
  "User/getProfile",
  async (thunkAPI) => {
    // const apiUrl = process.env.REACT_APP_API_BASEURL;
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.get(
        `${baseurl.authBaseURL}${companyProfileApiEndPoint.GetProfile}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { data: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
