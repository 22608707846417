import { createSlice } from "@reduxjs/toolkit";
import * as actions from "./userAction";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    companyUserData: [],
    createUSerData: [],
    deleteUserData: [],
    editUSerData: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetUserManagement(state) {
      for (const key in state) {
        state[key] = [];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actions.getCompanyUserList.pending, (state) => {
        state.loading = true;
      })
      .addCase(actions.getCompanyUserList.fulfilled, (state, action) => {
        state.companyUserData = action.payload.data;
        state.loading = false;
      })
      .addCase(actions.getCompanyUserList.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(actions.CreateUserCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(actions.CreateUserCompany.fulfilled, (state, action) => {
        state.createUSerData = action.payload.data;
        state.loading = false;
      })
      .addCase(actions.CreateUserCompany.rejected, (state) => {
        state.error = true;
        state.loading = false;
      })
      // .addCase(actions.EditUserCompany.pending, (state) => {
      //   state.loading = true;
      // })
      .addCase(actions.EditUserCompany.fulfilled, (state, action) => {
        state.editUSerData = action.payload.data;
        // state.loading = false;
      })
      .addCase(actions.EditUserCompany.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(actions.DeleteUserCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(actions.DeleteUserCompany.fulfilled, (state, action) => {
        state.deleteUserData = action.payload.data;
        state.loading = false;
      })
      .addCase(actions.DeleteUserCompany.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      });
  },
});
export const { resetUserManagement } = userSlice.actions;

export default userSlice.reducer;
