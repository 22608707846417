import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  baseurl,
  // companyProfileApiEndPoint,
  employeeApiEndpoint,
  loanApiEndPoint,
  notificationApiEndPoint,
} from "../../configs/serverApiConfig";

const apiUrl = process.env.REACT_APP_API_BASEURL;
const accessToken = localStorage.getItem("accessToken");

export const getLoanList = createAsyncThunk(
  "loan/getLoanList",
  async (searchNameMobileEmail, thunkAPI) => {
    try {
      const response = await axios.get(
        `${baseurl.loanBaseURL}${loanApiEndPoint.GetLoanList}`,
        {
          searchNameMobileEmail,
        },

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { data: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getLoanDetailsByUserID = createAsyncThunk(
  "loan/getLoanDetailsByUserID",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(
        `${baseurl.loanBaseURL}${loanApiEndPoint.GetLoanDetailsByUserID}/${id}`,

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { data: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getNotificationByUserId = createAsyncThunk(
  "notification/byUserID",
  async (rowId, thunkAPI) => {
    try {
      const response = await axios.post(
        `${apiUrl}${notificationApiEndPoint.GetNotificationByUserId}`,
        {
          userid: rowId, // Pass the row.id as a parameter
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { data: response?.data?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getallUserByCompanyLoan = createAsyncThunk(
  "user/getallUserByCompanyLoan",
  async (thunkAPI) => {
    const apiUrl = process.env.REACT_APP_API_BASEURL;
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.post(
        `${apiUrl}${employeeApiEndpoint.GetAllUserBYCompanyLoan}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { data: response?.data?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const GetLoanPendingList = createAsyncThunk(
  "loan/GetLoanPendingList",
  async (_, thunkAPI) => {
    // console.log("KKKk");
    try {
      const response = await axios.get(
        `${baseurl.loanBaseURL}${loanApiEndPoint.GetLoanPendingList}`,

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getLoanPendingList: response?.data };
    } catch (error) {
      console.log(error?.response, "eee");
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const GetLoanApprove = createAsyncThunk(
  "loan/GetLoanApproveReject",
  async (virtualId, thunkAPI) => {
    try {
      const response = await axios.get(
        `${baseurl.loanBaseURL}${loanApiEndPoint.GetLoanApproveEnd}/${virtualId}`,

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getLoanPendingApprove: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const GetLoanReject = createAsyncThunk(
  "loan/GetLoanApproveReject",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${baseurl.loanBaseURL}${loanApiEndPoint.GetLoanRejectEnd}`,

        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getLoanReject: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const GetLoanEMIHistory = createAsyncThunk(
  "loan/GetLoanEMIHistory",
  async (virtualId, thunkAPI) => {
    try {
      const response = await axios.get(
        `${baseurl.loanBaseURL}${loanApiEndPoint.GetLoanEMIHistoryEnd}/${virtualId}`,

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getLoanEMIHistory: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const GetLoanEMICalender = createAsyncThunk(
  "loan/GetLoanEMICalender",
  async (virtualId, thunkAPI) => {
    try {
      const response = await axios.post(
        `${baseurl.paymentBaseURL}${loanApiEndPoint.GetLoanEMICalenderEnd}/${virtualId}`,

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getLoanEMICalender: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
