import { createSlice } from "@reduxjs/toolkit";
import * as actions from "./loanAction";

export const loanSlice = createSlice({
  name: "loans",
  initialState: {
    employMangeTableData: [],
    getLoanPendingList: [],
    employLoanData: [],
    getallUserByCompanyLoan: [],
    getProfileData: [],
    getLoanDetailsByUserID: [],
    getLoanApproveReject: [],
    getLoanEMIHistory: [],
    getLoanEMICalender: [],
    EMIHistoryLoading: false,
    EMICalenderLoading: false,
    loading: false, // Add loading state
    error: null, // Add error state
  },
  reducers: {
    reset(state) {
      for (const key in state) {
        state[key] = [];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actions.getLoanList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(actions.getLoanList.fulfilled, (state, action) => {
        state.loading = false;
        state.employMangeTableData = action.payload.data;
      })
      .addCase(actions.getLoanList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(actions.getLoanDetailsByUserID.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(actions.getLoanDetailsByUserID.fulfilled, (state, action) => {
        state.loading = false;
        state.getLoanDetailsByUserID = action.payload.data;
      })
      .addCase(actions.getLoanDetailsByUserID.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(actions.getNotificationByUserId.fulfilled, (state, action) => {
        state.notificationByUserId = action.payload.data;
      })
      .addCase(actions.getallUserByCompanyLoan.fulfilled, (state, action) => {
        state.getallUserByCompanyLoan = action.payload.data;
      })

      .addCase(actions.GetLoanPendingList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(actions.GetLoanPendingList.fulfilled, (state, action) => {
        state.loading = false;
        state.getLoanPendingList = action.payload.getLoanPendingList;
      })
      .addCase(actions.GetLoanPendingList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(actions.GetLoanApprove.pending, (state) => {
        state.loading = true;
      })
      .addCase(actions.GetLoanApprove.fulfilled, (state, action) => {
        state.loading = false;
        state.getLoanApproveReject = action.payload.getLoanApproveReject;
      })
      .addCase(actions.GetLoanApprove.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(actions.GetLoanEMIHistory.pending, (state) => {
        state.EMIHistoryLoading = true;
      })
      .addCase(actions.GetLoanEMIHistory.fulfilled, (state, action) => {
        state.EMIHistoryLoading = false;
        state.getLoanEMIHistory = action.payload.getLoanEMIHistory;
      })
      .addCase(actions.GetLoanEMIHistory.rejected, (state) => {
        state.EMIHistoryLoading = false;
        state.error = true;
      })
      .addCase(actions.GetLoanEMICalender.pending, (state) => {
        state.EMICalenderLoading = true;
      })
      .addCase(actions.GetLoanEMICalender.fulfilled, (state, action) => {
        state.EMICalenderLoading = false;
        state.getLoanEMICalender = action.payload.getLoanEMICalender;
      })
      .addCase(actions.GetLoanEMICalender.rejected, (state) => {
        state.EMICalenderLoading = false;
        state.error = true;
      });
  },
});
export const { reset } = loanSlice.actions;

export default loanSlice.reducer;
