import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseurl, dashboardEndPoint } from "../../configs/serverApiConfig";
import { handleLogout } from "@store/auth/authSlicer";
const accessToken = localStorage.getItem("accessToken");

export const getDashboardData = createAsyncThunk(
  "User/getDashboardData",
  async (data, thunkAPI) => {
    console.log("hell");
    try {
      const response = await axios.post(
        `${baseurl.customerBaseURL}${dashboardEndPoint.GetDashBoard}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(response, "response");
      return { data: response?.data };
    } catch (error) {
      console.log(error, "eeee");
      if (error?.response.status === 401) {
        console.log("helloLogOut");
        thunkAPI.dispatch(handleLogout());
      }
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
