import { createSlice } from "@reduxjs/toolkit";
import * as actions from "./companyAction";

export const companySlice = createSlice({
  name: "company",
  initialState: {
    updateCompany: [],
    updateCompanyProfile: [],
    updateCompanyProfileUser: [],
    getProfileData: [],
    isLoading: false,
    isError: null,
  },
  reducers: {
    reset(state) {
      for (const key in state) {
        state[key] = [];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actions.UpdateCompany.fulfilled, (state, action) => {
        state.updateCompany = action.payload.data;
      })
      .addCase(actions.UpdateCompanyProfile.fulfilled, (state, action) => {
        state.updateCompanyProfile = action.payload.data;
      })
      .addCase(
        actions.UpdateCompanyProfileByUser.fulfilled,
        (state, action) => {
          state.updateCompanyProfileUser = action.payload.data;
        }
      )
      .addCase(actions.GetProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(actions.GetProfile.fulfilled, (state, action) => {
        state.getProfileData = action.payload.data;
        state.isLoading = false;
      })
      .addCase(actions.GetProfile.rejected, (state) => {
        state.isError = true;
        state.isLoading = false;
      });
  },
});
export const { reset } = companySlice.actions;

export default companySlice.reducer;
