import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { loanApiEndPoint } from "../../configs/serverApiConfig";

const apiUrl = process.env.REACT_APP_API_BASEURL;
const accessToken = localStorage.getItem("accessToken");

export const getLoanDetails = createAsyncThunk(
  "employLoanData/getLoanDetails",
  async (rowId, thunkAPI) => {
    try {
      const response = await axios.post(
        `${apiUrl}${loanApiEndPoint.GetLoanDetailsByUser}`,
        {
          id: rowId, // Pass the row.id as a parameter
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { data: response?.data?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getEmiHistory = createAsyncThunk(
  "EmiHistory/getEmiHistory",
  async (rowId, thunkAPI) => {
    const apiUrl = process.env.REACT_APP_API_BASEURL;
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.post(
        `${apiUrl}${loanApiEndPoint.GetTransactionByUserId}`,
        {
          id: rowId, // Pass the row.id as a parameter
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { data: response?.data?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getEmiCalender = createAsyncThunk(
  "EmiCalender/getEmiCalender",
  async (rowId, thunkAPI) => {
    const apiUrl = process.env.REACT_APP_API_BASEURL;
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.post(
        `${apiUrl}${loanApiEndPoint.GetEmiCalenderByUser}`,
        {
          id: rowId, // Pass the row.id as a parameter
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getEmiCalender: response?.data?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getAllByCompanyEmiData = createAsyncThunk(
  "user/GetAllByCompanyEmiData",
  async (thunkAPI) => {
    const apiUrl = process.env.REACT_APP_API_BASEURL;
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.get(
        `${apiUrl}${loanApiEndPoint.GetAllByCompany}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { data: response?.data?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
