import { createSlice } from "@reduxjs/toolkit";
import * as actions from "./loanEmiAction";

export const loanEmiSlice = createSlice({
  name: "loanEmi",
  initialState: {
    employLoanData: [],
    getEmiHistory: [],
    getEmiCalender: [],
    getallByCompanyEmiData: [],
  },
  reducers: {
    reset(state) {
      for (const key in state) {
        state[key] = [];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actions.getLoanDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(actions.getLoanDetails.fulfilled, (state, action) => {
        state.employLoanData = action.payload.data;
      })
      .addCase(actions.getLoanDetails.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(actions.getEmiHistory.fulfilled, (state, action) => {
        state.getEmiHistory = action.payload.getEmiHistory
      })
      .addCase(actions.getEmiCalender.fulfilled, (state, action) => {
        state.getEmiCalender = action.payload.getEmiCalender
      })
      .addCase(actions.getAllByCompanyEmiData.fulfilled, (state, action) => {
        state.getallByCompanyEmiData = action.payload.getallByCompanyEmiData
      })
  },
});
export const { reset } = loanEmiSlice.actions

export default loanEmiSlice.reducer;
