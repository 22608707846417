import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_ENDPOINTS } from "../../configs/apiEndPoints";
import { baseurl } from "../../configs/serverApiConfig";

const accessToken = localStorage.getItem("accessToken");
export const UploadFile = createAsyncThunk(
  "/Admin/uploadFile",
  async (UploadFileData, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append("filename[]", UploadFileData);
      const response = await axios.post(
        `${baseurl?.customerBaseURL}${API_ENDPOINTS.uploadFile}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
            // Set the content type explicitly
          },
        }
      );
      return { uploadFile: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
