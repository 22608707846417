import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseurl, escrowApiEndPoint } from "../../configs/serverApiConfig";

const accessToken = localStorage.getItem("accessToken");

export const GetBranchData = createAsyncThunk(
  "escrow/getBranchData",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${baseurl.paymentBaseURL}${escrowApiEndPoint.GetBranchData}`,

        data,

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { getBranchData: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const AddEscrowBank = createAsyncThunk(
  "escrow/AddEscrowBank",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${baseurl.loanBaseURL}${escrowApiEndPoint.AddEscrowPayBankAccount}`,

        data,

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { addEscrowBank: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const GetEscrowAccountStatement = createAsyncThunk(
  "escrow/GetEscrowAccountStatement",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${baseurl.paymentBaseURL}${escrowApiEndPoint.GetEscrowPayStatement}`,

        data,

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { getEscrowAccountStatement: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const DepositMoney = createAsyncThunk(
  "loan/DepositMoney",
  async (virtualId, thunkAPI) => {
    try {
      const response = await axios.get(
        `${baseurl.paymentBaseURL}${escrowApiEndPoint?.DepositMoney}/${virtualId}`,

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { depositMoney: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const GetEscrowBank = createAsyncThunk(
  "escrow/GetEscrowBank",
  async (thunkAPI) => {
    try {
      const response = await axios.get(
        `${baseurl.loanBaseURL}${escrowApiEndPoint.GetBank}`,

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { getEscrowBank: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
