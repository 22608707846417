import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseurl, companyApiEndPoint } from "../../configs/serverApiConfig";

const accessToken = localStorage.getItem("accessToken");

export const getCompanyUserList = createAsyncThunk(
  "User/getCompanyUserList",
  async (thunkAPI) => {
    try {
      const response = await axios.get(
        `${baseurl.customerBaseURL}${companyApiEndPoint.UserList}`,

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { data: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const CreateUserCompany = createAsyncThunk(
  "/user/createUser",
  async (data, thunkAPI) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await axios.post(
        `${baseurl?.customerBaseURL}${companyApiEndPoint.createUser}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { addUser: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const EditUserCompany = createAsyncThunk(
  "/user/EditUserCompany",
  async (data, thunkAPI) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await axios.put(
        `${baseurl?.customerBaseURL}${companyApiEndPoint.editUser}/${data?.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { addUser: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const DeleteUserCompany = createAsyncThunk(
  "/user/Delete",
  async (data, thunkAPI) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await axios.delete(
        `${baseurl?.customerBaseURL}${data}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { deleteUser: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// ​/company-user​/update​/{id}
