import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseurl, reportApiEndPoint } from "../../configs/serverApiConfig";

const accessToken = localStorage.getItem("accessToken");

export const getCommissionReport = createAsyncThunk(
  "report/commissionReport",
  async (date, thunkAPI) => {
    try {
      const response = await axios.post(
        `${baseurl.loanManagementBaseBaseURL}${reportApiEndPoint.GetCommissionReport}`,

        date,

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { data: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getLoanReport = createAsyncThunk(
  "report/LoanReport",
  async (date, thunkAPI) => {
    try {
      const response = await axios.post(
        `${baseurl.loanBaseURL}${reportApiEndPoint.GetLoanReport}`,

        date,

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { data: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const GetLoanByReport = createAsyncThunk(
  "/Loan/GetLoanByReport",
  async (data, thunkAPI) => {
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.post(
        `${baseurl?.loanBaseURL}${reportApiEndPoint.GetLoanReport}?page=${
          data?.page
        }&size=${data?.size}&sortBy=${data?.column ?? ""}`,
        data?.date,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getLoanByReport: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const GetEmiByReport = createAsyncThunk(
  "/Loan/GetEmiByReport",
  async (data, thunkAPI) => {
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.post(
        `${baseurl?.loanBaseURL}${reportApiEndPoint.GetEMIReport}?page=${
          data?.page
        }&size=${data?.size}&sortBy=${data?.column ?? ""}`,
        data?.date,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getEmiByReport: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const GetCommission = createAsyncThunk(
  "/Report/GetCommission",
  async (data, thunkAPI) => {
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.post(
        `${baseurl?.loanBaseURL}${reportApiEndPoint.GetCommissionReport}?page=${
          data?.page
        }&size=${data?.size}&sortBy=${data?.column ?? ""}`,
        data?.date,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getCommission: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);


export const getCompanyCommissionReport = createAsyncThunk(
  "/Report/commissionReportCompany",
  async(thunkAPI) =>{

    const accessToken = localStorage.getItem("accessToken"); 
    try{

      const response = await axios.get(
        `${baseurl?.loanBaseURL}${reportApiEndPoint.GetCompanyCommissionReport}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getCompanyCommission: response?.data };

    }catch(error){
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }

)
