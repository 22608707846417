import { createSlice } from "@reduxjs/toolkit";
import * as actions from "./attachmentAction";

export const attachmentSlice = createSlice({
  name: "attachment",
  initialState: {
    uploadFile: [],

  },
  reducers: {
    reset(state) {
      for (const key in state) {
        state[key] = [];
      }
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(actions.UploadFile.fulfilled, (state, action) => {
      state.uploadFile = action.payload.data
      })
  },
});
export const { reset } = attachmentSlice.actions
export default attachmentSlice.reducer;
