import { createSlice } from "@reduxjs/toolkit";
import * as actions from "./escrowAction";

export const escrowSlice = createSlice({
  name: "escrow",
  initialState: {
    getBranchData: [],
    addEscrowBank: [],
    getEscrowAccountStatement: [],
    getEscrowBank: [],
    isLoading: false,
    isLoadingBranch: false,
    isLoadingDeposit: false,
    error: null,
  },
  reducers: {
    reset(state) {
      for (const key in state) {
        state[key] = [];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actions.GetBranchData.pending, (state) => {
        state.isLoadingBranch = true;
      })
      .addCase(actions.GetBranchData.fulfilled, (state, action) => {
        state.getBranchData = action.payload?.getBranchData;
        state.isLoadingBranch = false;
      })
      .addCase(actions.GetBranchData.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoadingBranch = false;
      })
      .addCase(actions.AddEscrowBank.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(actions.AddEscrowBank.fulfilled, (state, action) => {
        state.addEscrowBank = action.payload?.addEscrowBank;
        state.isLoading = false;
      })
      .addCase(actions.AddEscrowBank.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(actions.GetEscrowAccountStatement.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(actions.GetEscrowAccountStatement.fulfilled, (state, action) => {
        state.getEscrowAccountStatement =
          action.payload?.getEscrowAccountStatement;
        state.isLoading = false;
      })
      .addCase(actions.GetEscrowAccountStatement.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(actions.DepositMoney.pending, (state) => {
        state.isLoadingDeposit = true;
      })
      .addCase(actions.DepositMoney.fulfilled, (state) => {
        // state.getEscrowAccountStatement =
        //   action.payload?.getEscrowAccountStatement;
        state.isLoadingDeposit = false;
      })
      .addCase(actions.DepositMoney.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoadingDeposit = false;
      })
      .addCase(actions.GetEscrowBank.pending, (state) => {
        state.isLoadingEscrowBank = true;
      })
      .addCase(actions.GetEscrowBank.fulfilled, (state, action) => {
        state.getEscrowBank = action.payload?.getEscrowBank;
        state.isLoadingEscrowBank = false;
      })
      .addCase(actions.GetEscrowBank.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoadingEscrowBank = false;
      });
  },
});
export const { reset } = escrowSlice.actions;

export default escrowSlice.reducer;
