// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
import homepageAPIdata from "../../src/views/pages/store";
import auth from "./auth/authSlicer";
import company from "./company/companySlicer";
import bank from "./bank/bankSlicer";
import loans from "./loans/loanSlicer";
import attachment from "./attachment/attachmentSlicer";
import loanEmi from "./loanEmi/loanEmiSlicer";
import report from "./report/reportSlice";
import user from "./user/userSlice";
import escrow from "./escrowAccount/escrowSlicer";
import dashBoard from "./dashboard/dashboardSlice";

const rootReducer = {
  navbar,
  layout,
  homepageAPIdata,
  auth,
  company,
  bank,
  loans,
  attachment,
  loanEmi,
  report,
  user,
  dashBoard,
  escrow,
};

export default rootReducer;
